import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { windowFinishContact } from "../../statics/forms.static";
import Button from "../General/Button.component";

const WindowStageThreeStyle = styled.div`
  padding: 8rem 0;
  .box {
    background: white;
    padding: 4rem;
    box-shadow: 0 0 50px 5px rgba(19, 126, 213, 0.3);
    display: flex;
    flex-direction: column;
    text-align: center;
    @media (max-width: 992px) {
      padding: 1rem;
    }
    .lead {
      margin-top: 8rem;
      margin-bottom: 2rem;
      font-size: 4rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
    }
    .sub {
      margin-bottom: 8rem;
    }
    .content {
      font-size: 2rem;
      max-width: 50%;
      margin: 0 auto;
      margin-bottom: 16rem;
    }
    .button-wrapper {
      display: flex;
      justify-content: center;
      gap: 3rem;
      margin: 2rem 0;
    }
    form button {
      margin-top: 3rem;
    }
    form > div {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      a {
        text-decoration: underline !important;
      }
    }
  }
`;

const WindowStageThree = ({ form, onChange, onSubmit }) => {
  return (
    <div className="container">
      <WindowStageThreeStyle>
        <div className="box">
          <form onSubmit={onSubmit}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                {...form.customername}
                onChange={onChange}
              />
              <TextField
                variant="outlined"
                {...form.customertel}
                onChange={onChange}
              />
              <TextField
                variant="outlined"
                {...form.customeremail}
                onChange={onChange}
              />
              <FormControlLabel
                control={<Checkbox {...form.buildin} onChange={onChange} />}
                label={<div>Kérek majd beépítést</div>}
              />
              <TextField
                variant="outlined"
                {...form.customeraddr}
                onChange={onChange}
              />
              <FormControlLabel
                control={<Checkbox {...form.acceptance} onChange={onChange} />}
                label={
                  <div>
                    Elfogadom az{" "}
                    <a
                      href="/adatvedelem"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      adatvédelmi nyilatkozatot
                    </a>{" "}
                    és a{" "}
                    <a
                      href="/felhasznaloi-feltetelek"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      felhasználói feltételeket
                    </a>
                    .
                  </div>
                }
              />
            </FormControl>
            <Button styleO={{ bg: "orange", shadowed: true }}>Elküldöm</Button>
          </form>
        </div>
      </WindowStageThreeStyle>
    </div>
  );
};

export default WindowStageThree;
