import React, { useState } from "react";
import styled from "styled-components";

const GYIKWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  padding: 6rem 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .left {
    .lead {
      font-size: 3.6rem;
      @media (max-width: 768px) {
        font-size: 2.8rem;
      }
      font-weight: 900;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 6rem;
      &:after {
        background: ${({ theme }) => theme.darkOrange};
        width: 20%;
        height: 5px;
        position: absolute;
        content: "";
        display: block;
        bottom: -3rem;
        left: 0;
      }
    }
    .content {
      color: ${(props) => props.theme.darkGray};
      line-height: 1.8;
    }
  }
  .right {
    .lead {
      font-weight: bold;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      &:before {
        content: "";
        position: relative;
        width: 2rem;
        background: ${(props) => props.theme.orange};
        height: 2px;
        display: inline-flex;
        margin-right: 1.5rem;
      }
    }
    .content {
      color: ${(props) => props.theme.darkGray};
      padding-bottom: 2rem;
      &.hidden  {
        display: none;
      }
    }
  }
`;

const WindowGYIK = () => {
  const [active, setActive] = useState(0);
  return (
    <div className="container">
      <GYIKWrapper>
        <div className="left">
          <p className="lead">gyakran ismételt kérdések</p>
          <p className="content">
            Rengeteg kérdés felmerül új nyílászáró választásakor. A sok éves
            gyártási - és beépítési tapasztalatunkkal a hátunk mögött
            összeszedtük önnek a gyakran felmerülő kérdéseket melyeket
            ügyfeleink feltettek számunkra.
          </p>
        </div>
        <div className="right">
          <div className="item">
            <div className="lead" onClick={() => setActive(0)}>
              Mit jelent, hogy a végső ár változhat?
            </div>
            <div className={`${active !== 0 && "hidden"} content`}>
              Az áraink akkor változhatnak, ha felmérés után esetlegesen a
              méretváltozik. Változhat annak függvényében is, ha a vásárlónak
              egyedi igényeihez mérten redőnyt kell felszerelni. Ilyenkor a
              nyílászáró toktoldózást kaphat.
            </div>
          </div>
          <div className="item">
            <div className="lead" onClick={() => setActive(1)}>
              Hogy kell nézni a nyitási irányt?
            </div>
            <div className={`${active !== 1 && "hidden"} content`}>
              Ha ablakokról van szó belső nézetből történik, amerre nyílik az
              ablak (vagy amelyik oldalon van az ablak pánt), olyan lesz a
              nyitás irány is. Ajtók esetében van külső és belső nézet, itt ezt
              is szemügyre kell venni és ennek függvényében hasonlóan az
              ablakokéhoz lehet megállapítani a nyitás irányt.
            </div>
          </div>
          <div className="item">
            <div className="lead" onClick={() => setActive(2)}>
              Hogyan történik a beépítés?
            </div>
            <div className={`${active !== 2 && "hidden"} content`}>
              Beépítést minden esetben személyes felmérés előzi meg, ha a
              vásárló igényli, hogy mi végezzük a beépítést is. Ilyenkor
              felmérjük a gyártási méreteket. Beépítést minden esetben hozzá
              értő szakembereink végzik.
            </div>
          </div>
          <div className="item">
            <div className="lead" onClick={() => setActive(3)}>
              Mennyi időt vesz igénybe a gyártás?
            </div>
            <div className={`${active !== 3 && "hidden"} content`}>
              Általánosságban fehér színű ablakoknál 4 hét a gyártási idő, ez
              változhat, ha beszállítóink késnek az anyag, illetve vasalat és
              más segédanyagok kiszállításával. Standard színes áruknál a
              gyártási idő 4-6 hét. Alakos és nem standard színű nyílászáróknál
              ez az idő 6-12 hét is lehet.
            </div>
          </div>
          <div className="item">
            <div className="lead" onClick={() => setActive(4)}>
              Hogyan történik a fizetés?
            </div>
            <div className={`${active !== 4 && "hidden"} content`}>
              Raktár készleti ablakoknál, előre utalással vagy személyesen az
              áruelvitelekor. Ha Ön minket választ és a beépítést is a mi cégünk
              csinálja, akkor az árajánlat szerint az ár 50%-át előre, a fent
              maradó részét a beépítés után.
            </div>
          </div>
        </div>
      </GYIKWrapper>
    </div>
  );
};

export default WindowGYIK;
