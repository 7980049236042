import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import BC from "../components/General/BC.component";
import Layout from "../components/General/Layout.component";
import WindowGYIK from "../components/Window/WindowGYIK.component";
import WindowStageNull from "../components/Window/WindowStageNull.component";
import WindowStageOne from "../components/Window/WindowStageOne.component";
import WindowText from "../components/Window/WindowText.component";
import { windowPage } from "../statics/pageInfo.static";
import { genPageInfo } from "../utils/genPageInfo.util";
import { cloneDeep } from "lodash";
import { planFormStatic, windowFinishContact } from "../statics/forms.static";
import WindowStageTwo from "../components/Window/WindowStageTwo.component";
import WindowStageThree from "../components/Window/WindowStageThree.component";
import WindowStageFour from "../components/Window/WindowStageFour.component";

const AblakTervezo = () => {
  const [stage, setStage] = useState(0);
  const [design, setDesign] = useState(null);
  const [planForm, setPlanForm] = useState(cloneDeep(planFormStatic));
  const [formArray, setFormArray] = useState([]);
  const [contactForm, setContactForm] = useState(
    cloneDeep(windowFinishContact)
  );
  const [finish, setFinish] = useState(false);

  useEffect(() => {
    if (design !== null && stage === 0) {
      setStage(1);
    }
  }, [design, setStage]);

  useEffect(() => {
    setPlanForm(changeHandler(planForm, "kivitel", design));
  }, [design]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stage]);

  const changeHandler = (oldForm, fieldName, value) => {
    let form = cloneDeep(oldForm);
    if (fieldName === "acceptance" || fieldName === "buildin")
      form[fieldName].checked = value;
    else form[fieldName].value = value;
    return form;
  };

  const onChange = (args) => {
    if (args.target.name === "acceptance")
      setPlanForm(
        changeHandler(planForm, args.target.name, args.target.checked)
      );
    else
      setPlanForm(changeHandler(planForm, args.target.name, args.target.value));
  };

  const onChangeContact = (args) => {
    if (args.target.name === "acceptance" || args.target.name === "buildin")
      setContactForm(
        changeHandler(contactForm, args.target.name, args.target.checked)
      );
    else
      setContactForm(
        changeHandler(contactForm, args.target.name, args.target.value)
      );
  };

  const reset = () => {
    setPlanForm(cloneDeep(planFormStatic));
    setStage(0);
    setDesign(null);
  };

  const hardReset = () => {
    setPlanForm(cloneDeep(planFormStatic));
    setContactForm(cloneDeep(windowFinishContact));
    setFormArray([]);
    setFinish(false);
    setStage(0);
    setDesign(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFormArray([...formArray, cloneDeep(planForm)]);
    if (finish) setStage(2);
    else reset();
  };

  const moreClick = () => {
    reset();
  };

  const sendClick = () => {
    setStage(3);
  };

  const onSubmitContact = (e) => {
    e.preventDefault();
    fetch("http://localhost:5001/api/v1/window", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        windows: JSON.stringify(formArray),
        customer: JSON.stringify(contactForm),
      }),
    })
      .then((response) => response.json())
      .then(() => {})
      .catch((e) => console.error(e));
    setStage(4);
  };

  const deleteItem = (index) => {
    setFormArray(cloneDeep(formArray).filter((_, i) => i !== index));
  };

  return (
    <Layout>
      {genPageInfo(windowPage)}
      <BC
        title="Ablak tervező"
        sub={[{ text: "Kezdőlap", href: "/" }, { text: "Ablak tervező" }]}
      />
      <WindowText />
      <div
        style={{
          backgroundImage: "url(/service-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div className="stages">
          {stage === 0 && <WindowStageNull setDesign={setDesign} />}
          {stage === 1 && (
            <WindowStageOne
              planForm={planForm}
              onChange={onChange}
              design={design}
              reset={reset}
              onSubmit={onSubmit}
              finish={() => setFinish(true)}
            />
          )}
          {stage === 2 && (
            <WindowStageTwo
              moreClick={moreClick}
              sendClick={sendClick}
              formArray={formArray}
              deleteItem={deleteItem}
            />
          )}
          {stage === 3 && (
            <WindowStageThree
              form={contactForm}
              onChange={onChangeContact}
              onSubmit={onSubmitContact}
            />
          )}
          {stage === 4 && <WindowStageFour hardReset={hardReset} />}
        </div>
        <WindowGYIK />
      </div>
    </Layout>
  );
};

export default AblakTervezo;
