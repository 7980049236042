import React from "react";
import styled from "styled-components";

const WindowTextStyle = styled.div`
  text-align: center;
  padding: 6rem 0;
  p {
    color: ${(props) => props.theme.darkGray};
  }
  p:first-of-type {
    font-weight: 900;
    margin-bottom: 3rem;
    color: black;
    font-size: 2rem;
  }
`;

const WindowText = () => {
  return (
    <div className="container">
      <WindowTextStyle>
        <p>Tervezze meg saját ablakát!</p>
        <p>
          A lenti ablakösszerakó segítségével könnyedén összeállíthatja magának
          új ablakát. Az itt elkészített tervek csak hozzávetőleges képet
          festenek a tényleges árról, ebben nem szerepelnek az elkészítéshez
          szükséges különböző alkatrészek (csavarok, pántok stb stb), így
          beküldés után kollégáink a lehető leghamarabb felveszik önnel a
          kapcsolatot a pontosítás végett.
        </p>
      </WindowTextStyle>
    </div>
  );
};

export default WindowText;
