import styled from "styled-components";
import React from "react";
import Button from "../General/Button.component";
import { navigate } from "gatsby";

const WindowStageTwoStyle = styled.div`
  padding: 8rem 0;
  .box {
    background: white;
    padding: 4rem;
    box-shadow: 0 0 50px 5px rgba(19, 126, 213, 0.3);
    display: flex;
    flex-direction: column;
    text-align: center;
    @media (max-width: 992px) {
      padding: 1rem;
    }
    .lead {
      margin-top: 8rem;
      margin-bottom: 8rem;
      font-size: 4rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.5rem;

      @media (max-width: 992px) {
        font-size: 2.4rem;
      }
    }
    .sub {
      margin-bottom: 8rem;
    }
    .content {
      font-size: 2rem;
      max-width: 50%;
      margin: 0 auto;
      margin-bottom: 16rem;
    }
    .button-wrapper {
      display: flex;
      justify-content: center;
      gap: 3rem;
      margin-bottom: 2rem;
      @media (max-width: 992px) {
        flex-direction: column;
      }
    }
  }
`;

const WindowStageFour = ({ hardReset }) => {
  return (
    <div className="container">
      <WindowStageTwoStyle>
        <div className="box">
          <div className="lead">Köszönjük!</div>
          <div className="sub">
            A tervet/terveket sikeresen beküldte számunkra. Kollégánk hamarosan
            felveszi önnel a kapcsolat az erléhetősegi egyikén.
          </div>
          <div className="button-wrapper">
            <Button
              styleO={{ bg: "orange", shadowed: true }}
              callback={() => hardReset()}
            >
              Új ablak készítés
            </Button>
            <Button
              styleO={{ bg: "darkBlue", shadowed: true }}
              callback={() => navigate("/ajto-tervezo")}
            >
              Új ajtó készítés
            </Button>
          </div>
        </div>
      </WindowStageTwoStyle>
    </div>
  );
};

export default WindowStageFour;
