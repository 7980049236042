import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/material";

const WindowStageNullStyle = styled.div`
  padding: 8rem 0;
  .box {
    background: white;
    padding: 4rem;
    box-shadow: 0 0 50px 5px rgba(19, 126, 213, 0.3);
    select {
      padding: 1rem 2rem;
      outline: none;
      border-color: ${(props) => props.theme.lightGray};
      font-weight: bold;
      width: 25rem;
    }
    .top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 4rem 0;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 3rem;
        & > div:first-of-type {
          order: 2;
        }
      }
      .lead {
        font-size: 3.6rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 0.5rem;
        @media (max-width: 768px) {
          font-size: 2.8rem;
        }
      }
      .sub {
        font-size: 2rem;
        color: #222222;
      }
    }
    .middle {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
      gap: 3rem;
      place-items: center;
      padding: 6rem 0;
      cursor: pointer;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        p {
          text-transform: uppercase;
          font-weight: 900;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding-top: 4rem;
      padding-bottom: 1rem;
      border-top: 2px solid ${(props) => props.theme.lightGray};
      a {
        color: ${(props) => props.theme.darkBlue} !important;
      }
      & > div:last-of-type {
        margin-left: 5rem;
      }
      .lead {
        margin-bottom: 1rem;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 2rem;
        & > div:last-of-type {
          margin-left: 0rem;
        }
      }
    }
    @media (max-width: 992px) {
      padding: 1rem;
    }
  }
`;

const WindowStageNull = ({ setDesign }) => {
  const handleSelect = (e) => {
    navigate(`/${e.target.value}`);
  };

  return (
    <div className="container">
      <WindowStageNullStyle>
        <div className="box">
          <div className="top">
            <div>
              <div className="lead">
                Tervezzen
                <br />
                egyszerűen
              </div>
              <div className="sub">Válasszon kivitelt:</div>
            </div>
            <FormControl>
              <InputLabel id="change-desginer-label">Tervező</InputLabel>
              <Select
                labelId="change-desginer-label"
                id="change-desginer"
                label="Tervező"
                value="ablak-tervezo"
                onChange={handleSelect}
              >
                <MenuItem value="ablak-tervezo">Ablak tervező</MenuItem>
                <MenuItem value="ajto-tervezo">Ajtó tervező</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="middle">
            <div className="item" onClick={() => setDesign(0)} data-cy="0">
              <StaticImage src="../../images/windows/fix.jpeg" alt="" />
              <p>fix</p>
            </div>
            <div className="item" onClick={() => setDesign(1)} data-cy="1">
              <StaticImage src="../../images/windows/buko.jpeg" alt="" />
              <p>bukó</p>
            </div>
            <div className="item" onClick={() => setDesign(2)} data-cy="2">
              <StaticImage src="../../images/windows/buko-nyilo.jpeg" alt="" />
              <p>bukó-nyíló</p>
            </div>
            <div className="item" onClick={() => setDesign(3)} data-cy="3">
              <StaticImage
                src="../../images/windows/kozepen-felnyilo.jpeg"
                alt=""
              />
              <p>középen felnyíló</p>
            </div>
            <div className="item" onClick={() => setDesign(4)} data-cy="4">
              <StaticImage src="../../images/windows/tokosztott.jpeg" alt="" />
              <p>tokosztott</p>
            </div>
          </div>
          <div className="bottom">
            <div>
              <StaticImage src="../../images/windows/decor.png" alt="" />
            </div>
            <div>
              <div className="lead">Alakos ablakra lenne szüksége?</div>
              <div className="content">
                Amennyiben a standard formáktól eltérő nyílászáróra van szüksége
                kérjük <Link to="/kapcsolat">ide</Link> kattintva keresse fel
                ügyfélszolgálatunkat vagy írjon emailt a{" "}
                <a href="mailto: hello@debrecenablak.hu">
                  hello@debrecenablak.hu
                </a>{" "}
                címre ami után kollégáink készségesen segítenek a tervek
                elkészítésésben.
              </div>
            </div>
          </div>
        </div>
      </WindowStageNullStyle>
    </div>
  );
};

export default WindowStageNull;
