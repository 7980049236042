import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import Button from "../General/Button.component";
import RefreshIcon from "@mui/icons-material/Refresh";

const WindowStageOneStyle = styled.div`
  padding: 8rem 0;
  .box {
    background: white;
    padding: 4rem;
    box-shadow: 0 0 50px 5px rgba(19, 126, 213, 0.3);
    select {
      padding: 1rem 2rem;
      outline: none;
      border-color: ${(props) => props.theme.lightGray};
      font-weight: bold;
      width: 25rem;
    }
    .top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 4rem 0;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 3rem;
        & > div:first-of-type {
          order: 2;
        }
      }
      .lead {
        font-size: 3.6rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 0.5rem;
        @media (max-width: 768px) {
          font-size: 2.8rem;
        }
      }
      .sub {
        font-size: 2rem;
        color: #222222;
      }
    }
    .middle {
      display: grid;
      grid-template-columns: 1fr 1fr;
      & > div {
        padding: 0 4rem;
      }
      .left {
        border-right: 2px solid ${(props) => props.theme.lightGray};
        & > div {
          gap: 2rem;
          display: flex;
          flex-direction: column;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        width: 100%;
        .reset {
          text-transform: uppercase;
          cursor: pointer;
          margin-top: 1rem;
          display: inline-block;
          text-align: center;
          svg {
            color: ${(props) => props.theme.darkBlue};
            top: 50%;
            transform: translateY(-50%);
            position: relative;
          }
        }
        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          p {
            text-transform: uppercase;
            font-weight: 900;
          }
        }
        .contact-form {
          margin-top: 4rem;
          padding-top: 4rem;
          border-top: 1px solid ${(props) => props.theme.lightGray};
          display: grid;
          place-items: center;
          height: 100%;
          p {
            font-weight: normal;
            color: ${(props) => props.theme.darkGray};
            text-transform: none;
            text-align: center;
          }
          &__buttons {
            display: flex;
            justify-content: space-around;
          }
          & > div {
            gap: 2rem;
          }
        }
      }
      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        .left {
          border: none;
          padding: 0;
        }
        .right {
          padding: 0;
          .reset svg {
            top: auto;
            transform: translateY(0);
          }
          .contact-form {
            display: flex;
            flex-direction: column;
            &__buttons  {
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }
    }
    @media (max-width: 992px) {
      padding: 1rem;
    }
  }
`;

const WindowStageOne = ({
  planForm,
  onChange,
  design,
  reset,
  onSubmit,
  finish,
}) => {
  const handleSelect = (e) => {
    navigate(`/${e.target.value}`);
  };

  return (
    <div className="container">
      <WindowStageOneStyle>
        <form onSubmit={onSubmit}>
          <div className="box">
            <div className="top">
              <div>
                <div className="lead">
                  Tervezzen
                  <br />
                  egyszerűen
                </div>
                <div className="sub">Kérjük adja meg az alábbi adatokat:</div>
              </div>
              <FormControl>
                <InputLabel id="change-desginer-label">Tervező</InputLabel>
                <Select
                  labelId="change-desginer-label"
                  id="change-desginer"
                  label="Tervező"
                  value="ablak-tervezo"
                  onChange={handleSelect}
                >
                  <MenuItem value="ablak-tervezo">Ablak tervező</MenuItem>
                  <MenuItem value="ajto-tervezo">Ajtó tervező</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="middle">
              <div className="left">
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    {...planForm.planname}
                    onChange={onChange}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      {...planForm.mm1}
                      onChange={onChange}
                      fullWidth
                    />
                    x
                    <TextField
                      variant="outlined"
                      {...planForm.mm2}
                      onChange={onChange}
                      fullWidth
                    />
                  </div>

                  <FormControl fullWidth>
                    <InputLabel id="szinezet-label">Színezet</InputLabel>
                    <Select
                      labelId="szinezet-label"
                      {...planForm.szinezet}
                      onChange={onChange}
                    >
                      {planForm.szinezet.options.map((szo) => (
                        <MenuItem value={szo} key={szo}>
                          {szo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {planForm.szinezet.value !== "Fehér" &&
                    planForm.szinezet.value !== "" && (
                      <div
                        style={{
                          gap: "2rem",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="kulsoszin-label">
                            Külső szín
                          </InputLabel>
                          <Select
                            labelId="kulsoszin-label"
                            {...planForm.kulsoszin}
                            onChange={onChange}
                          >
                            {planForm.kulsoszin.options.map((szo) => (
                              <MenuItem value={szo} key={szo}>
                                {szo}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {planForm.szinezet.value === "Kívül belül színes" && (
                          <FormControl fullWidth>
                            <InputLabel id="belsoszin-label">
                              Belső szín
                            </InputLabel>
                            <Select
                              labelId="belsoszin-label"
                              {...planForm.belsoszin}
                              onChange={onChange}
                            >
                              {planForm.belsoszin.options.map((szo) => (
                                <MenuItem value={szo} key={szo}>
                                  {szo}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </div>
                    )}

                  <FormControl fullWidth>
                    <InputLabel id="nyitasiirany-label">
                      Nyitási irány
                    </InputLabel>
                    <Select
                      labelId="nyitasiirany-label"
                      {...planForm.nyitasiirany}
                      onChange={onChange}
                    >
                      {planForm.nyitasiirany.options.map((szo) => (
                        <MenuItem value={szo} key={szo}>
                          {szo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="uveg-label">Üveg</InputLabel>
                    <Select
                      labelId="uveg-label"
                      {...planForm.uveg}
                      onChange={onChange}
                    >
                      {planForm.uveg.options.map((szo) => (
                        <MenuItem value={szo} key={szo}>
                          {szo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="uvegtipus-label">Üveg típusa</InputLabel>
                    <Select
                      labelId="uvegtipus-label"
                      {...planForm.uvegtipus}
                      onChange={onChange}
                    >
                      {planForm.uvegtipus.options.map((szo) => (
                        <MenuItem value={szo} key={szo}>
                          {szo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="parkanyfogado-label">
                      Párkányfogadó
                    </InputLabel>
                    <Select
                      labelId="parkanyfogado-label"
                      {...planForm.parkanyfogado}
                      onChange={onChange}
                    >
                      {planForm.parkanyfogado.options.map((szo) => (
                        <MenuItem value={szo} key={szo}>
                          {szo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    {...planForm.mennyiseg}
                    onChange={onChange}
                  />
                  <TextareaAutosize
                    minRows={5}
                    {...planForm.egyeb}
                    onChange={onChange}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </div>
              <div className="right">
                {design === 0 && (
                  <div>
                    <StaticImage src="../../images/windows/fix.jpeg" alt="" />
                    <p>fix</p>
                  </div>
                )}
                {design === 1 && (
                  <div>
                    <StaticImage src="../../images/windows/buko.jpeg" alt="" />
                    <p>bukó</p>
                  </div>
                )}
                {design === 2 && (
                  <div>
                    <StaticImage
                      src="../../images/windows/buko-nyilo.jpeg"
                      alt=""
                    />
                    <p>bukó-nyíló</p>
                  </div>
                )}
                {design === 3 && (
                  <div>
                    <StaticImage
                      src="../../images/windows/kozepen-felnyilo.jpeg"
                      alt=""
                    />
                    <p>középen felnyíló</p>
                  </div>
                )}
                {design === 4 && (
                  <div>
                    <StaticImage
                      src="../../images/windows/tokosztott.jpeg"
                      alt=""
                    />
                    <p>tokosztott</p>
                  </div>
                )}
                <div className="reset" onClick={reset}>
                  <RefreshIcon /> Törlöm és újrakezdem
                </div>
                <div className="contact-form">
                  <p>
                    Amennyiben szükséges rakjon össze egy új tervet vagy ha
                    befejezte csak egyszerűen küldje el számunkra.
                  </p>
                  <div className="contact-form__buttons">
                    <Button styleO={{ bg: "darkBlue", shadow: true }}>
                      Másikat is készítek
                    </Button>
                    <Button
                      styleO={{ bg: "orange", shadow: true }}
                      callback={() => finish()}
                    >
                      Befejezem
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </WindowStageOneStyle>
    </div>
  );
};

export default WindowStageOne;
