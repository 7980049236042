import styled from "styled-components";
import React from "react";
import Button from "../General/Button.component";
import { StaticImage } from "gatsby-plugin-image";
import RefreshIcon from "@mui/icons-material/Refresh";

const WindowStageTwoStyle = styled.div`
  padding: 8rem 0;
  .box {
    background: white;
    padding: 4rem;
    box-shadow: 0 0 50px 5px rgba(19, 126, 213, 0.3);
    display: flex;
    flex-direction: column;
    text-align: center;
    .lead {
      margin-top: 8rem;
      margin-bottom: 2rem;
      font-size: 4rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
    }
    .sub {
      margin-bottom: 8rem;
    }
    .content {
      font-size: 2rem;
      max-width: 50%;
      margin: 0 auto;
      margin-bottom: 6rem;
    }
    .button-wrapper {
      display: flex;
      justify-content: center;
      gap: 3rem;
      margin-bottom: 2rem;
    }
    .generated-view {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      place-items: center;
      grid-auto-rows: 1fr;
      gap: 3rem;
      margin-bottom: 6rem;
      p {
        font-weight: 900;
        text-transform: uppercase;
        margin: 2rem 0;
      }
      span {
        text-transform: uppercase;
        display: inline-block;
        cursor: pointer;
        svg {
          color: ${(props) => props.theme.darkBlue};
          position: relative;
          top: 0.2rem;
        }
      }
    }
    @media (max-width: 992px) {
      padding: 1rem;
      .lead {
        font-size: 2rem;
      }
      .sub {
        margin-bottom: 3rem;
      }
      .content {
        max-width: 100%;
        margin-bottom: 3rem;
        font-size: 1.4rem;
      }
      .button-wrapper {
        flex-direction: column;
      }
    }
  }
`;

const WindowStageTwo = ({ moreClick, sendClick, formArray, deleteItem }) => {
  return (
    <div className="container">
      <WindowStageTwoStyle>
        <div className="box">
          <div className="lead">Gratulálunk!</div>
          <div className="sub">Sikeresen elkészítette új ablakának tervét.</div>
          <div className="content">
            Lentebb láthatja a terveket melyeket elkészített.
          </div>
          <div className="generated-view">
            {formArray.length < 1 && (
              <div className="generated-view">
                <p>Nincs megjeleníthető elem!</p>
              </div>
            )}
            {formArray.map((item, i) => (
              <div className="item" key={`gi${i}`}>
                {item.kivitel.value === 0 && (
                  <div>
                    <StaticImage src="../../images/windows/fix.jpeg" alt="" />
                    <p>{item.planname.value}</p>
                    <span onClick={() => deleteItem(i)}>
                      <RefreshIcon /> Törlöm
                    </span>
                  </div>
                )}
                {item.kivitel.value === 1 && (
                  <div>
                    <StaticImage src="../../images/windows/buko.jpeg" alt="" />
                    <p>{item.planname.value}</p>
                    <span onClick={() => deleteItem(i)}>
                      <RefreshIcon /> Törlöm
                    </span>
                  </div>
                )}
                {item.kivitel.value === 2 && (
                  <div>
                    <StaticImage
                      src="../../images/windows/buko-nyilo.jpeg"
                      alt=""
                    />
                    <p>{item.planname.value}</p>
                    <span onClick={() => deleteItem(i)}>
                      <RefreshIcon /> Törlöm
                    </span>
                  </div>
                )}
                {item.kivitel.value === 3 && (
                  <div>
                    <StaticImage
                      src="../../images/windows/kozepen-felnyilo.jpeg"
                      alt=""
                    />
                    <p>{item.planname.value}</p>
                    <span onClick={() => deleteItem(i)}>
                      <RefreshIcon /> Törlöm
                    </span>
                  </div>
                )}
                {item.kivitel.value === 4 && (
                  <div>
                    <StaticImage
                      src="../../images/windows/tokosztott.jpeg"
                      alt=""
                    />
                    <p>{item.planname.value}</p>
                    <span onClick={() => deleteItem(i)}>
                      <RefreshIcon /> Törlöm
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="button-wrapper">
            <Button
              styleO={{ bg: "orange", shadow: true }}
              callback={moreClick}
            >
              Új ablak tervezése
            </Button>
            {formArray.length > 0 && (
              <Button
                styleO={{ bg: "darkBlue", shadow: true }}
                callback={sendClick}
              >
                Kérem az ajánlatot
              </Button>
            )}
          </div>
        </div>
      </WindowStageTwoStyle>
    </div>
  );
};

export default WindowStageTwo;
