export const planFormStatic = {
  kivitel: {
    id: "kivitel",
    name: "kivitel",
    value: "",
  },
  planname: {
    id: "planname",
    name: "planname",
    value: "",
    required: true,
    placeholder: "Pl.: Konyha tokosztott",
    label: "Terv neve",
    type: "text",
  },
  mm1: {
    id: "mm1",
    name: "mm1",
    value: "",
    required: true,
    label: "Méret - szélesség",
    placeholder: "mm",
    type: "number",
    inputProps: { min: 1 },
  },
  mm2: {
    id: "mm2",
    name: "mm2",
    value: "",
    required: true,
    label: "Méret - hosszúság",
    placeholder: "mm",
    type: "number",
    inputProps: { min: 1 },
  },
  szinezet: {
    id: "szinezet",
    name: "szinezet",
    value: "",
    label: "Színezet",
    required: true,
    options: ["Fehér", "Kívül színes", "Kívül belül színes"],
  },
  kulsoszin: {
    id: "kulsoszin",
    name: "kulsoszin",
    value: "",
    label: "Külső szín",
    required: true,
    options: ["Dió", "Antracit", "Aranytölgy", "Mahagóni", "Egyedi szín"],
  },
  belsoszin: {
    id: "belsoszin",
    name: "belsoszin",
    value: "",
    label: "Belső szín",
    required: true,
    options: ["Dió", "Antracit", "Aranytölgy", "Mahagóni", "Egyedi szín"],
  },
  nyitasiirany: {
    id: "nyitasiirany",
    name: "nyitasiirany",
    value: "",
    label: "Nyitási irány",
    required: true,
    options: ["Jobbos", "Balos"],
  },
  uveg: {
    id: "uveg",
    name: "uveg",
    value: "",
    label: "Üveg",
    required: true,
    options: ["2 rétegű", "3 rétegű"],
  },
  uvegtipus: {
    id: "uvegtipus",
    name: "uvegtipus",
    label: "Üveg típusa",
    value: "",
    required: true,
    options: ["Sima", "Savmart", "Csincsilla", "Meleg perem", "Fatörzs"],
  },
  parkanyfogado: {
    id: "parkanyfogado",
    name: "parkanyfogado",
    value: "",
    label: "Párkányfogadó",
    required: true,
    options: ["Nem kérek", "Méreten belül", "Méreten kívül"],
  },
  mennyiseg: {
    id: "mennyiseg",
    name: "mennyiseg",
    value: "",
    required: true,
    label: "Mennyiség",
    type: "number",
    inputProps: { min: 1 },
  },
  egyeb: {
    id: "egyeb",
    name: "egyeb",
    value: "",
    placeholder: "Extra információ, ha szükséges",
  },
};

export const windowFinishContact = {
  customername: {
    id: "customername",
    name: "customername",
    value: "",
    required: true,
    label: "Hogyan szólíthatunk?",
    type: "text",
  },
  customertel: {
    id: "customertel",
    name: "customertel",
    value: "",
    required: true,
    placeholder: "+36 __ ___ _____",
    label: "Telefonszám",
    type: "tel",
  },
  customeremail: {
    id: "customeremail",
    name: "customeremail",
    value: "",
    required: true,
    label: "Email",
    type: "email",
  },
  customeraddr: {
    id: "customeraddr",
    name: "customeraddr",
    value: "",
    required: true,
    label: "Irányítószám",
    type: "text",
  },
  buildin: {
    id: "buildin",
    name: "buildin",
    checked: false,
  },
  acceptance: {
    id: "acceptance",
    name: "acceptance",
    checked: false,
    required: true,
  },
};
